import styled from 'styled-components';

import { makeStyles } from '@material-ui/core/styles';
export const Wrapper = styled.div`
    background: linear-gradient(to bottom, #e7ffb3, #707070);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Header = styled.div`
    background: #333;
    height: 10vh;
`;

export const useStyles = () => {
    const styles = makeStyles((theme) => {
        const drawerWidth = 240;

        return {
            root: {
                display: 'flex'
            },
            toolbar: {
                paddingRight: 24 // keep right padding when drawer closed
            },
            toolbarIcon: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0 8px',
                ...theme.mixins.toolbar
            },
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                })
            },
            appBarShift: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen
                })
            },
            menuButton: {
                marginRight: 36
            },
            menuButtonHidden: {
                display: 'none'
            },
            drawerPaper: {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen
                })
            },
            drawerPaperClose: {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                    overflowX: 'auto'
                }
            },
            appBarSpacer: theme.mixins.toolbar,
            content: {
                flexGrow: 1,
                height: '100vh'
            },
            container: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4)
            },
            paper: {
                padding: theme.spacing(2),
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column'
            },
            fixedHeight: {
                height: 240
            },
            logo: {
                flexGrow: 1
            },
            logo_img: {
                marginTop: theme.spacing(1),
                marginRight: theme.spacing(1),
                top: theme.spacing(1),
                display: 'flex',
                width: '100%'
            },
            absolute: {
                position: 'absolute',
                left: theme.spacing(40),
                top: theme.spacing(12)
            },

            stickToBottom: {
                width: '100%',
                position: 'fixed',
                bottom: 0,
                zIndex: 1
            }
        };
    });
    return styles();
};

export const useStyles2 = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            overflow: 'unset'
        }
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        },
        [theme.breakpoints.down('600')]: {
            flex: '1 1 auto',
            overflow: 'hidden',
            paddingTop: theme.spacing(8),
            display: 'initial'
        }
    },
    contentContainer: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingRight: theme.spacing(2)
        },
        [theme.breakpoints.down('600')]: {
            // paddingTop: theme.spacing(8)
        }
    },
    content: {
        [theme.breakpoints.up('sm')]: {
            flex: '1 1 auto',
            height: '100%',
            overflow: '1 1 auto'
        },
        [theme.breakpoints.down('600')]: {
            //paddingTop: theme.spacing(8)
        }
    }
}));
