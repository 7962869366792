import React, { useReducer, useEffect } from 'react';

const reducerVenta = (info, newInfo) => {
    if (newInfo === null) {
        sessionStorage.removeItem('contextVenta');
        return initialVentaContext;
    }
    return { ...info, ...newInfo };
};

const VentaContext = React.createContext();

export const initialVentaContext = {
    minor: {
        page: 0,
        limit: 5,
        name: '',
        startDate: null,
        endDate: null,
        filters: {
            CREADO: true,
            DESPACHADO: true,
            ENTREGADO: true,
            PAGADO: true
        }
    },
    mayor: {
        page: 0,
        limit: 5,
        name: '',
        startDate: null,
        endDate: null,
        filters: {
            CREADO: true,
            DESPACHADO: true,
            ENTREGADO: true,
            PAGADO: true
        }
    }
};

const localStateVenta = JSON.parse(sessionStorage.getItem('contextVenta'));

function VentaProvider(props) {
    const [contextVenta, setContextVenta] = useReducer(
        reducerVenta,
        localStateVenta || initialVentaContext
    );

    useEffect(() => {
        sessionStorage.setItem('contextVenta', JSON.stringify(contextVenta));
    }, [contextVenta]);

    return (
        <VentaContext.Provider value={{ contextVenta, setContextVenta }}>
            {props.children}
        </VentaContext.Provider>
    );
}

export { VentaContext, VentaProvider };
