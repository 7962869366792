/* eslint-disable react/prop-types */
import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LayersIcon from "@material-ui/icons/Layers";
import StoreIcon from "@material-ui/icons/Store";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import { RiPlantFill } from "react-icons/ri";
import { FaPeopleCarry } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { AiOutlineLineChart } from "react-icons/ai";

export const itemsAuth = [
  { title: "Inicio", href: "/", icon: HomeIcon },
  {
    title: "Productos",
    href: ["/productos-franquicia/", "/productobaja"],
    icon: RiPlantFill,
    nestedObjects: [
      {
        title: "Listado",
        href: "/productos-franquicia/",
      },
      {
        title: "Bajas",
        href: "/productobaja",
      },
    ],
  },
  { title: "Pedidos", href: "/pedidos", icon: ShoppingCartIcon },
  {
    title: "Mayoristas",
    href: ["/mayoristas", "/ventasm", "/ventam/despacho/"],
    icon: StoreIcon,
    nestedObjects: [
      { title: "Listado", href: "/mayoristas" },
      {
        title: "Ventas",
        href: "/ventasm",
        opt: { mayorista: true },
      },
      {
        title: "Despacho",
        href: "/ventam/despacho/",
        opt: { mayorista: true },
      },
    ],
  },
  {
    title: "Minoristas",
    href: ["/clientes?offset=0&limit=5", "/ventas", "/ventas/despacho/"],
    icon: StoreIcon,
    nestedObjects: [
      { title: "Listado", href: "/clientes?offset=0&limit=5" },
      {
        title: "Ventas",
        href: "/ventas",
        opt: { mayorista: false },
      },
      {
        title: "Despacho",
        href: "/ventas/despacho/",
        opt: { mayorista: false },
      },
    ],
  },
  { title: "Reparto", href: "/repartos", icon: FaPeopleCarry },
  {
    title: "Usuarios",
    href: ["/vendedores", "/repartidor", "/encargados", "/admins"],
    icon: FaUsers,
    nestedObjects: [
      { title: "Vendedores", href: "/vendedores" },
      {
        title: "Repartidores",
        href: "/repartidor",
      },
      {
        title: "Encargados",
        href: "/encargados",
        icon: LayersIcon,
      },
      {
        title: "Administradores",
        href: "/admins",
        icon: LayersIcon,
      },
    ],
  },

  {
    icon: AiOutlineLineChart,
    title: "Estadisticas",
    nestedObjects: [
      { title: "Ventas", href: "/estadisticas/ventas" },
      { title: "Productos", href: "/estadisticas/productos" },

    ],
  },

  {
    title: "Perfil",
    href: "/perfil",
    icon: PersonIcon,
  },
  ,
];
