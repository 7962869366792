import React, { useContext, useEffect } from "react";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";

import NavItem from "./NavItem";
import { InfoContext } from "../../../../services/context/user-context";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: "#00b37a",
  },
  navBar: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
}));

const NavBar = ({ onMobileClose, openMobile, openAlert, history, links }) => {
  const { info } = useContext(InfoContext);
  const classes = useStyles();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [history.location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          onClick={() => {
            history.push("/perfil");
          }}
        >
          {info.name[0] +
            info.name.substring(
              parseInt(info.name.search(" ")) + 1,
              parseInt(info.name.search(" ")) + 2
            )}
        </Avatar>

        <Typography className={classes.name} color="textPrimary" variant="h5">
          {info.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {info.rol}
        </Typography>
      </Box>
      <Divider />
      <Box p={2} className={classes.navBar}>
        <List>
          {links &&
            links.map((item) => (
              <NavItem
                href={item.href}
                key={item.title + item.href}
                title={item.title}
                icon={item.icon ?? null}
                nestedObject={item.nestedObjects ? item.nestedObjects : []}
                history={history}
                salir={
                  item.href === "/salir"
                    ? () => {
                        onMobileClose();
                        openAlert(true);
                      }
                    : false
                }
              />
            ))}
        </List>
        <div>
          <Divider />
          <NavItem
            href={"/salir"}
            key={"Salir"}
            title={"Salir"}
            icon={ExitToAppIcon}
            nestedObject={[]}
            history={history}
            salir={() => {
              onMobileClose();
              openAlert(true);
            }}
          />
        </div>
      </Box>

      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
