import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { InfoContext } from "../../../services/context/user-context";
import { itemsVendedor } from "../vendedor/listItems";
import { useStyles2 } from "./styles";
import TopBar from "./TopBar";
import NavBar from "./NavBar/index";
import { itemsAuth } from "../auth/listItems";
import { itemsCentral } from "../central/listItems";
import { itemsEncargado } from "../encargado/listItems";
import LoadingBar from "react-top-loading-bar";
import { useLoading } from "../../../context/LoadingBar";

export default function LoggedLayout({ children }) {
  const { info, setInfo } = useContext(InfoContext);
  const [avatar, setAvatar] = React.useState("");
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);
  const [isAlertOpen, setAlertOpen] = React.useState(false);
  const rol = info.rol;
  const { loadingBarProgress, reset } = useLoading();

  const classes = useStyles2();

  useEffect(() => {
    setAvatar(
      info.name.substring(0, 1) +
      info.name.substring(
        parseInt(info.name.search(" ")) + 1,
        parseInt(info.name.search(" ")) + 2
      )
    );
  }, []);

  let links = [];
  if (rol === "ADMIN") {
    links = itemsAuth;
  } else if (rol === "VENDEDOR") {
    links = itemsVendedor;
  } else if (rol === "CENTRAL") {
    links = itemsCentral;
  } else if (rol === "ENCARGADO") {
    links = itemsEncargado;
  }

  return (
    <div className={classes.root}>
      <div>
        <LoadingBar
          progress={loadingBarProgress}
          height={3}
          color="rgba(0, 97, 84, 1)"
          onLoaderFinished={reset}
        />
      </div>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        isAlertOpen={isAlertOpen}
        openAlert={(value) => {
          setAlertOpen(value);
        }}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        links={links}
        history={children.props.history}
        openAlert={(value) => {
          setAlertOpen(value);
        }}
      />

      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

LoggedLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
