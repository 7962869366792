import React, { createContext, useContext, useState } from "react";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const [loadingBarProgress, setLoadingBarProgress] = useState(0);

    const complete = () => {
        setLoadingBarProgress(100);
    };

    const reset = () => {
        setLoadingBarProgress(0);
    };

    const add = (value) => {
        setLoadingBarProgress((prevProgress) => prevProgress + value);
    };

    return (
        <LoadingContext.Provider value={{ loadingBarProgress, complete, reset, add }}>
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = () => {
    return useContext(LoadingContext);
};
