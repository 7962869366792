import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, ListItem } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { InfoContext } from "../../../../services/context/user-context";
import { useStyles } from "./NavItem.style";

const NavItem = ({
  className,
  href,
  icon: Icon = null,
  title,
  history,
  salir,
  nestedObject,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { info, setInfo } = React.useContext(InfoContext);

  const isOpen = () => {
    if (open) return true;
    if (open === null) return false;

    if (nestedObject) {
      let hasToOpen = false;

      nestedObject.forEach((item) => {
        if (item.href === info.path?.href) {
          if (info.path.opt) {
            if (item.opt.mayorista === info.path.opt.mayorista) {
              hasToOpen = true;
            } else {
              hasToOpen = false;
            }
          } else {
            hasToOpen = true;
          }
        }
      });

      return hasToOpen;
    }
  };

  return nestedObject?.length > 0 ? (
    <>
      {" "}
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={() => setOpen((old) => (old ? null : true))}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
        </Button>
      </ListItem>
      <Collapse in={isOpen()} timeout="auto" unmountOnExit>
        {nestedObject.map((obj) => {
          return (
            <ListItem
              key={obj.title}
              button
              className={clsx(classes.item, className)}
            >
              <Button
                className={
                  obj.href === history.location.pathname
                    ? clsx(classes.button, classes.buttonActive)
                    : classes.button
                }
                onClick={() => {
                  if (salir) {
                    salir();
                  } else {
                    history.push(obj.href, obj.opt);
                    setInfo({ info, path: obj });
                  }
                }}
              >
                {Icon && <ArrowRightIcon className={classes.icon} size="20" />}
                <span className={classes.title}>{obj.title}</span>
              </Button>
            </ListItem>
          );
        })}
      </Collapse>
    </>
  ) : (
    <ListItem className={classes.item} disableGutters {...rest}>
      <Button
        className={
          href === history.location.pathname
            ? clsx(classes.button, classes.buttonActive)
            : classes.button
        }
        onClick={() => {
          if (salir) {
            salir();
          } else {
            history.push(href);

            setInfo({
              info,
              path: {
                href: href,
              },
            });
          }
        }}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  icon: PropTypes.elementType,
  title: PropTypes.string,
  nestedObject: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default NavItem;
