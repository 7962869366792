/* eslint-disable react/prop-types */
import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import GroupIcon from "@material-ui/icons/Group";
import { makeStyles } from "@material-ui/core/styles";
import EcoIcon from "@material-ui/icons/Eco";
import StoreIcon from "@material-ui/icons/Store";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import { ListItemNav } from "../components/ListItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { RiPlantFill } from "react-icons/ri";
import { FaTruckLoading } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
}));

export const itemsCentral = [
  { title: "Inicio", href: "/home-central", icon: HomeIcon },
  {
    title: "Productos",
    href: ["/productos", "/productoprecio"],
    icon: RiPlantFill,
    nestedObjects: [
      { title: "Listado", href: "/productos" },
      {
        title: "Precios",
        href: "/productoprecio",
      },
    ],
  },
  { title: "Compras", href: "/compras", icon: ShoppingCartIcon },
  { title: "Franquicias", href: "/franquicias", icon: StoreIcon },

  { title: "Pedidos", href: "/pedidos-central", icon: FaTruckLoading },

  {
    title: "Perfil",
    href: "/perfil-central",
    icon: PersonIcon,
  },
  {
    href: "/salir",
    icon: ExitToAppIcon,
    title: "Salir",
  },
];
/* 
export default function MainListItems({ history }) {
    return (
        <div>
            <ListItemNav
                icon={<HomeIcon />}
                text={'Inicio'}
                link={'/home'}
                history={history}
            />

            <ListItemNav
                icon={<EcoIcon />}
                text={'Productos'}
                history={history}
                nestedObjects={[
                    { text: 'Listado', link: '/productos' },
                    {
                        text: 'Precios',
                        link: '/productoprecio'
                    }
                ]}
            />

            <ListItemNav
                icon={<ShoppingCartIcon />}
                text={'Compras'}
                link={'/compras'}
                history={history}
            />

            <ListItemNav
                icon={<StoreIcon />}
                text={'Franquicias'}
                history={history}
                link={'/franquicias'}
            />

            <ListItemNav
                icon={<GroupIcon />}
                text={'Pedidos'}
                history={history}
                nestedObjects={[
                    { text: 'Listado', link: '/pedidos-central' },
                    {
                        text: 'Despacho',
                        link: '/pedidos-despacho'
                    }
                ]}
            />

            <ListItemNav
                icon={<PersonIcon />}
                text={'Perfil'}
                history={history}
                link={'/perfil'}
            />
        </div>
    );
}
 */
