import React from "react";
import { Router } from "react-router-dom";

import history from "./services/history";
import Routes from "./routes";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import GlobalStyles from "./styles/global";
import { InfoProvider } from "./services/context/user-context";
import { VentaProvider } from "./services/context/venta-context";
import { LoadingProvider } from "./context/LoadingBar";

var styles = require("./styles/theme.json");
const theme = createTheme({
  palette: styles.palette,
});

function App() {
  document.title = "Citricos Pampa";

  return (
    <InfoProvider>
      <LoadingProvider>
        <ThemeProvider theme={theme}>
          <VentaProvider>
            <Router history={history}>
              <Routes />
              <GlobalStyles />
            </Router>
          </VentaProvider>


        </ThemeProvider>
      </LoadingProvider>
    </InfoProvider>
  );
}

export default App;
