/* eslint-disable react/prop-types */
import React from "react";
import EcoIcon from "@material-ui/icons/Eco";
import StoreIcon from "@material-ui/icons/Store";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { RiPlantFill } from "react-icons/ri";

export const itemsVendedor = [
  { title: "Productos", href: "/productos-franquicia", icon: RiPlantFill },
  {
    title: "Mayoristas",
    href: ["/mayoristas", "/ventasm"],
    icon: StoreIcon,
    nestedObjects: [
      { title: "Listado", href: "/mayoristas" },
      {
        title: "Ventas",
        href: "/ventasm",
        opt: { mayorista: true },
      },
    ],
  },
  {
    title: "Minoristas",
    href: ["/clientes", "/ventas"],
    icon: StoreIcon,
    nestedObjects: [
      { title: "Listado", href: "/clientes" },
      {
        title: "Ventas",
        href: "/ventas",
        opt: { mayorista: false },
      },
    ],
  },
  {
    title: "Perfil",
    href: "/perfil",
    icon: PersonIcon,
  },
];
