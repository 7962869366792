import React, { useReducer, useEffect } from "react";

let reducer = (info, newInfo) => {
  if (newInfo === null) {
    localStorage.removeItem("info");
    return initialState;
  }
  return { ...info, ...newInfo };
};

//const initialState = { id_user:'', rol:'',username: '', loggedIn: false, access:'', refresh:'',loaded:false}

const initialState = {};

const InfoContext = React.createContext();

const localState = JSON.parse(localStorage.getItem("info"));
function InfoProvider(props) {
  const [info, setInfo] = useReducer(reducer, localState || initialState);

  useEffect(() => {
    localStorage.setItem("info", JSON.stringify(info));
  }, [info]);

  return (
    <InfoContext.Provider value={{ info, setInfo }}>
      {props.children}
    </InfoContext.Provider>
  );
}

export { InfoContext, InfoProvider };
