import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import interceptor from "../services/interceptor";

const SignIn = React.lazy(() => import("../pages/SignIn"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Compra = React.lazy(() => import("../pages/Compra"));
const newCompra = React.lazy(() => import("../pages/Compra/newCompra"));
const CompraItem = React.lazy(() => import("../pages/Compra/compraItem"));
const ProductoFranquicia = React.lazy(() =>
  import("../pages/Producto/franquicia/index")
);
const Producto = React.lazy(() =>
  import("../pages/Producto/central/index_central")
);
const Venta = React.lazy(() => import("../pages/Venta"));
const VentaItem = React.lazy(() => import("../pages/Venta/ventaItem"));
const DespachoM = React.lazy(() => import("../pages/despacho/despacho"));
const newVenta = React.lazy(() => import("../pages/Venta/newVenta"));

const VentaMayorista = React.lazy(() =>
  import("../pages/VentaMayorista/index")
);
const VentaMayoristaItem = React.lazy(() =>
  import("../pages/VentaMayorista/ventaItem")
);
const Perfil = React.lazy(() => import("../pages/Perfil/perfil"));
const Cliente = React.lazy(() => import("../pages/Clientes"));
const Vendedor = React.lazy(() => import("../pages/vendedor"));
const Mayorista = React.lazy(() => import("../pages/mayorista"));
const PerfilMayorista = React.lazy(() =>
  import("../pages/mayorista/PerfilMayorista/perfil")
);
const Reparto = React.lazy(() => import("../pages/Reparto/index"));
const RepartoItem = React.lazy(() => import("../pages/Reparto/RepartoItem"));
const RepartoMobile = React.lazy(() => import("../pages/RepartoMobile/index"));
const Producto_precio_Central = React.lazy(() =>
  import("../pages/Producto/central/producto_precios_central")
);
const Pedidos = React.lazy(() => import("../pages/Pedido/franquicia/index"));
const NewPedido = React.lazy(() => import("../pages/Pedido/newPedido"));
const PedidoItem = React.lazy(() => import("../pages/Pedido/PedidoItem"));
const NotFound = React.lazy(() => import("../pages/NotFound/NotFoundView"));
const Franquicia = React.lazy(() => import("../pages/franquicia"));
const PedidosCentral = React.lazy(() =>
  import("../pages/Pedido/central/central")
);

const PedidoRevision = React.lazy(() =>
  import("../pages/Pedido/PedidoRevision")
);
const DashboardCentral = React.lazy(() => import("../pages/DashboardCentral"));
const DashboardEncargado = React.lazy(() =>
  import("../pages/DashboardEncargado")
);

const ResetPassword = React.lazy(() => import("../pages/ResetPassword/index"));
const ChangePassword = React.lazy(() =>
  import("../pages/ResetPassword/changePassword")
);
//React.lazy(()=> import newVentaMayorista = React.lazy(()=> import('../pages/VentaMayorista/newVenta'));
const NewVentaComponent = React.lazy(() =>
  import("../pages/Venta/components/newVenta/newVenta.component")
);
const BajaProducto = React.lazy(() => import("../pages/Producto/baja"));
const EstadisticasVentas = React.lazy(() => import("../pages/Estadisticas/Ventas/index"));
const EstadisticasProducts = React.lazy(() => import("../pages/Estadisticas/Productos/index"));



export default function Routes() {
  interceptor();

  return (
    <Switch>
      <Route
        title="Inicio"
        path="/"
        exact
        component={Dashboard}
        isPrivate
        isFranquicia
      />

      <Route
        title="Productos"
        path="/productos-franquicia"
        component={ProductoFranquicia}
        isPrivate
        isVendedor
      />
      <Route
        title="Baja de productos"
        path="/productobaja"
        component={BajaProducto}
        isPrivate
      />
      <Route
        title="Clientes minoristas"
        path="/clientes"
        component={Cliente}
        isPrivate
        isVendedor
      />
      <Route
        title="Clientes mayoristas"
        path="/mayoristas"
        exact
        component={Mayorista}
        isPrivate
        isVendedor
      />
      <Route
        title="Clientes mayoristas"
        path="/mayoristas/item/:id_cliente"
        exact
        component={PerfilMayorista}
        isPrivate
        isVendedor
      />
      <Route
        title="Ventas"
        key="venta-mayorista"
        path="/ventasm"
        exact
        component={Venta}
        isPrivate
        isVendedor
      />
      <Route
        title="Ventas"
        path="/ventasm/create"
        exact
        component={NewVentaComponent}
        isPrivate
        isVendedor
      />
      <Route
        title="Ventas"
        key="venta-minorista"
        path="/ventas"
        exact
        component={Venta}
        isPrivate
        isVendedor
      />
      <Route
        title="Ventas"
        path="/ventas/create"
        exact
        component={newVenta}
        isPrivate
        isVendedor
      />
      <Route
        title="Ventas"
        path="/ventas/item/"
        exact
        component={VentaItem}
        isPrivate
        isVendedor
      />
      <Route
        title="Ventas"
        path="/ventasm/item/"
        exact
        component={VentaMayoristaItem}
        isPrivate
        isVendedor
      />
      <Route
        title="Despacho de ventas"
        path="/ventam/despacho/"
        exact
        component={DespachoM}
        isEncargado
        isPrivate
      />
      <Route
        title="Despacho de ventas"
        path="/ventas/despacho/"
        exact
        component={DespachoM}
        isEncargado
        isPrivate
      />
      <Route
        title="Pedidos"
        path="/pedidos"
        exact
        component={Pedidos}
        isFranquicia
        isPrivate
      />
      <Route
        title="Pedidos"
        path="/pedidos/item"
        exact
        component={PedidoItem}
        isPrivate
      />
      <Route
        title="Pedidos"
        path="/pedidos/revision"
        exact
        component={PedidoRevision}
        isFranquicia
        isPrivate
      />
      <Route
        title="Pedidos"
        path="/pedidos/create"
        exact
        component={NewPedido}
        isFranquicia
        isPrivate
      />

      <Route
        title="Reparto de ventas"
        path="/repartos"
        exact
        component={Reparto}
        isEncargado
        isPrivate
      />
      <Route
        title="Reparto de ventas"
        path="/repartos/item"
        exact
        component={RepartoItem}
        isEncargado
        isPrivate
      />

      <Route
        title="Vendedores"
        path="/vendedores"
        exact
        component={Vendedor}
        isEncargado
        isPrivate
      />
      <Route
        title="Administradores"
        path="/admins"
        exact
        component={Vendedor}
        isFranquicia
        isPrivate
      />
      <Route
        title="Repartidores"
        path="/repartidor"
        exact
        component={Vendedor}
        isEncargado
        isPrivate
      />
      <Route
        title="Encargados"
        path="/encargados"
        exact
        component={Vendedor}
        isPrivate
      />
      <Route
        title="Inicio"
        path="/home-encargado"
        exact
        component={DashboardEncargado}
        isPrivate
        isEncargado
      />

      <Route
        title="Inicio"
        path="/home-central"
        exact
        component={DashboardCentral}
        isPrivate
        isCentral
      />

      <Route
        title="Productos"
        path="/productos"
        component={Producto}
        isPrivate
        isCentral
      />
      <Route
        title="Precios de productos"
        path="/productoprecio"
        component={Producto_precio_Central}
        isPrivate
        isCentral
      />
      <Route
        title="Pedidos"
        path="/pedidos-central"
        exact
        component={PedidosCentral}
        isCentral
        isPrivate
      />

      <Route
        title="Compras"
        path="/compras"
        exact
        component={Compra}
        isPrivate
        isCentral
      />
      <Route
        title="Compras"
        path="/compras/item"
        exact
        isCentral
        component={CompraItem}
        isPrivate
      />
      <Route
        title="Nueva Compra"
        path="/compras/create"
        exact
        isCentral
        component={newCompra}
        isPrivate
      />
      <Route
        title="Franquicias"
        path="/franquicias"
        exact
        component={Franquicia}
        isCentral
        isPrivate
      />

      <Route
        title="Repartos"
        path="/repartos/mobile"
        component={RepartoMobile}
        isPrivate
        isReparto
      />



      <Route
        title="Estadisticas Ventas"
        path="/estadisticas/ventas"
        component={EstadisticasVentas}
        isPrivate
        isFranquicia
      />

      <Route
        title="Estadisticas Productos"
        path="/estadisticas/productos"
        component={EstadisticasProducts}
        isPrivate
        isFranquicia
      />
      <Route
        title="Perfil"
        path="/perfil"
        exact
        component={Perfil}
        isVendedor
        isPrivate
      />
      <Route
        title="Perfil"
        path="/perfil-central"
        exact
        component={Perfil}
        isCentral
        isPrivate
      />
      <Route title="Inicio de sesión" path="/signin" component={SignIn} />
      <Route
        title="Reestablecer contraseña"
        path="/password"
        component={ResetPassword}
      />
      <Route
        title="Reestablecer contraseña"
        path="/reset-password/:token"
        component={ChangePassword}
      />
      <Route
        title="Error"
        path="/error"
        component={NotFound}
        isError
        isPrivate
      />
      <Route title="Inicio de sesión" path="*" component={SignIn} />
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
    </Switch>
  );
}
