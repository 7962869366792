import React, { Suspense, useContext } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import DefaultLayout from "../pages/_layouts/default";
import LoggedLayout from "../pages/_layouts/logged";
import { InfoContext } from "../services/context/user-context";
import { ErrorBoundary } from "react-error-boundary";
import NotFound from "../pages/NotFound/NotFoundView";
import { Grid } from "@material-ui/core";
import CircularLoader from "../components/extras/loader";
import Page from "../components/Page";

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  isReparto = false,
  isVendedor = false,
  isEncargado = false,
  isCentral = false,
  isFranquicia = false,
  isError = false,
  path,
  title = "",
  ...rest
}) {
  const { info } = useContext(InfoContext);
  /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */

  const signed = info.loggedIn;
  const rol = info.rol;

  // ruta privada y no logueado -> a login
  if (isPrivate && !signed) {
    return <Redirect to="/signin" />;
  }

  // ruta publica y logueado -> ERROR
  if (!isPrivate && signed) {
    return <Redirect to="/" />;
  }

  if (path === "/" && rol === "CENTRAL") {
    return <Redirect to="/home-central" />;
  }

  if (path === "/" && rol === "VENDEDOR") {
    return <Redirect to="/productos-franquicia" />;
  }

  if (path === "/" && rol === "ENCARGADO") {
    return <Redirect to="/home-encargado" />;
  }

  // ruta central y no es central  -> a error
  if (isCentral && rol !== "CENTRAL") {
    return <Redirect to="/error" />;
  }

  // ruta vendedor y no vendedor ni admin -> ERROR
  if (
    !isError &&
    isVendedor &&
    rol !== "ADMIN" &&
    rol !== "ENCARGADO" &&
    rol !== "VENDEDOR"
  ) {
    return <Redirect to="/error" />;
  }

  // ruta no vendedor y no vendedor -> ERROR
  if (!isVendedor && !isError && rol === "VENDEDOR") {
    console.log("ruta no vendedor y no vendedor -> ERROR");
    return <Redirect to="/error" />;
  }

  // ruta encargado y no encargado ni admin -> ERROR
  if (!isError && isEncargado && rol !== "ADMIN" && rol !== "ENCARGADO") {
    return <Redirect to="/error" />;
  }

  // ruta franquicia y no admin
  if (isFranquicia && rol !== "ADMIN") {
    console.log("ruta franquicia y no admin");
    return <Redirect to="/error" />;
  }

  // repadortir y ruta no reparto -> a repartos
  if (rol === "REPARTIDOR" && !isReparto) {
    console.log("repadortir y ruta no reparto");
    return <Redirect to="/repartos/mobile" />;
  }

  let Layout;

  if (signed) {
    switch (rol) {
      case "CENTRAL":
        Layout = LoggedLayout;
        break;
      case "ADMIN":
        Layout = LoggedLayout;
        break;
      case "ENCARGADO":
        Layout = LoggedLayout;
      case "VENDEDOR":
        Layout = LoggedLayout;
        break;
      case "REPARTIDOR":
        Layout = DefaultLayout;
        break;

      default:
        Layout = DefaultLayout;
        break;
    }
  } else {
    Layout = DefaultLayout;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <ErrorBoundary
            {...props}
            FallbackComponent={NotFound}
            onError={myErrorHandler}
          >
            <Suspense
              fallback={
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ minHeight: "100vh" }}
                >
                  <Grid item xs={3}>
                    <CircularLoader />
                  </Grid>
                </Grid>
              }
            >
              <Page title={title}>
                <Component {...props} />
              </Page>
            </Suspense>
          </ErrorBoundary>
        </Layout>
      )}
    />
  );
}

const myErrorHandler = (error, info) => {
  console.log(error, info);
};

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isReparto: PropTypes.bool,
  isVendedor: PropTypes.bool,
  isEncargado: PropTypes.bool,
  isCentral: PropTypes.bool,
  isFranquicia: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
