import React from "react";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
import logo from "../../assets/img/404.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
}));

const NotFoundView = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="md">
        <Typography align="center" color="textPrimary" variant="h4">
          404: La página que busca no está disponible
        </Typography>
        <Typography align="center" color="textPrimary" variant="subtitle2">
          Verifique la ruta ingresada
        </Typography>
        <Box textAlign="center">
          <img alt="Under development" className={classes.image} src={logo} />
        </Box>
      </Container>
    </Box>
  );
};

export default NotFoundView;
