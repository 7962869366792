import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
  nested: {
    paddingLeft: theme.spacing(1),
  },
  buttonSelected: {
    backgroundColor: "#f5f5f5",
  },
  buttonActive: {
    backgroundColor: "#c1d7d0",
    "&:hover": {
      backgroundColor: "#c1d7d0",
    },
  },
}));
