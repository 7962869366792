import axios from "axios";
import { Redirect } from "react-router-dom";
import React, { useContext } from "react";
import { InfoContext } from "../services/context/user-context";
import createAuthRefreshInterceptor from "axios-auth-refresh";

var jwt = require("jsonwebtoken");
const dotenv = require("dotenv");

dotenv.config();

// Function that will be called to refresh authorization

// Instantiate the interceptor (you can chain it as it returns the axios instance)

export default () => {
  const { info, setInfo } = useContext(InfoContext);

  const refreshAuthLogic = (failedRequest) => {
    axios({
      method: "get",
      url: process.env.REACT_APP_API_URL + "/refresh",
      headers: {
        refresh: info.refresh,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((tokenRefreshResponse) => {
        if (tokenRefreshResponse.data.success) {
          setInfo({
            ...info,
            access: tokenRefreshResponse.data.tokens.access,
          });
          failedRequest.response.config.headers["access"] =
            tokenRefreshResponse.data.tokens.access;
          return Promise.resolve();
        } else {
          setInfo({
            id_user: "",
            username: "",
            loggedIn: false,
            access: "",
            refresh: "",
          });
        }
      })
      .catch((error) => {
        setInfo({
          id_user: "",
          username: "",
          loggedIn: false,
          access: "",
          refresh: "",
        });
      });
  };

  createAuthRefreshInterceptor(axios, refreshAuthLogic);

  axios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};
