/* eslint-disable react/prop-types */
import React from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LayersIcon from "@material-ui/icons/Layers";
import EcoIcon from "@material-ui/icons/Eco";
import StoreIcon from "@material-ui/icons/Store";
import HomeIcon from "@material-ui/icons/Home";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import PersonIcon from "@material-ui/icons/Person";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { FaPeopleCarry } from "react-icons/fa";
import { RiPlantFill } from "react-icons/ri";

export const itemsEncargado = [
  { title: "Inicio", href: "/home-encargado", icon: HomeIcon },
  {
    title: "Productos",
    href: ["/productos-franquicia/", "/productobaja"],
    icon: RiPlantFill,
    nestedObjects: [
      {
        title: "Listado",
        href: "/productos-franquicia/",
      },
      {
        title: "Bajas",
        href: "/productobaja",
      },
    ],
  },
  {
    title: "Mayoristas",
    href: ["/mayoristas", "/ventasm", "/ventam/despacho/"],
    icon: StoreIcon,
    nestedObjects: [
      { title: "Listado", href: "/mayoristas" },
      {
        title: "Ventas",
        href: "/ventasm",
        opt: { mayorista: true },
      },
      {
        title: "Despacho",
        href: "/ventam/despacho/",
        opt: { mayorista: true },
      },
    ],
  },
  {
    title: "Minoristas",
    href: ["/clientes?offset=0&limit=5", "/ventas", "/ventas/despacho/"],
    icon: StoreIcon,
    nestedObjects: [
      { title: "Listado", href: "/clientes?offset=0&limit=5" },
      {
        title: "Ventas",
        href: "/ventas",
        opt: { mayorista: false },
      },
      {
        title: "Despacho",
        href: "/ventas/despacho/",
        opt: { mayorista: false },
      },
    ],
  },
  { title: "Reparto", href: "/repartos", icon: FaPeopleCarry },

  { title: "Vendedores", href: "/vendedores", icon: LayersIcon },
  {
    title: "Repartidores",
    href: "/repartidor",
    icon: AirportShuttleIcon,
  },
  {
    title: "Perfil",
    href: "/perfil",
    icon: PersonIcon,
  },
];
